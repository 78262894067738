import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

import { useOnRouteChange } from '@/ducks/routeChange';
import { useAppDispatch } from '@/store';
import { type TOptional } from '@/types/common';

import { clearWarning } from '../actions';
import { WarningTarget } from '../types';

// TODO: This doesn't work as intended, when we try to trigger the warning on the different page,
// it got cleared, as hook renders before route change
const useClearWarningOnAway = (targets: WarningTarget[], path?: string) => {
  const dispatch = useAppDispatch();
  const currentPath = usePathname()!;
  const initialPathRef = useRef<string>();
  const isActiveRef = useRef<TOptional<boolean>>(true);

  if (!initialPathRef.current) {
    initialPathRef.current = currentPath;
  }

  useEffect(
    () => () => {
      dispatch(clearWarning(targets));
      isActiveRef.current = undefined;
    },
    [],
  );

  useOnRouteChange(({ isPathChanged, prevPath }) => {
    const targetPath = path || initialPathRef.current;
    if (isActiveRef.current && isPathChanged && targetPath && prevPath === targetPath) {
      dispatch(clearWarning(targets));
    }
  });
};

export default useClearWarningOnAway;
