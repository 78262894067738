'use client';

import { useFetchPackagesAndSailings } from '@/ducks/pages/useFetchPackagesAndSailings';

type PackageResultsDataLoaderProps = {
  children: React.ReactNode;
};

const PackageResultsDataLoader = ({ children }: PackageResultsDataLoaderProps) => {
  useFetchPackagesAndSailings();
  return children;
};

export default PackageResultsDataLoader;
