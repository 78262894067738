import { useId } from 'react';

import UIResource from '@/components/UIResource';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { useAppSelector } from '@/store';

import SortPriceType from '../SortPriceType';
import SortRefinementForPackages from '../SortRefinementForPackages';
import AdvancedSelectionSkeleton from './Skeleton';

import './AdvancedSelection.scss';

const AdvancedSelection = () => {
  const sailingsCount = useAppSelector(selectFilteredSailings)?.length || 0;
  const isLoading = useAppSelector(selectIsFilteringLoading);
  const labelId = useId(); // this hack required to make sailings count pronunciation work in safari+voiceOver (known issue: provoke double pronunciation on nvda and safari+voiceOver)

  return (
    <>
      <div aria-atomic="true" aria-labelledby={labelId} className="sr-only" role="status">
        <div id={labelId}>
          <UIResource id="ChooseVoyage.Results.showing" />{' '}
          <b suppressHydrationWarning>
            <UIResource id="ChooseVoyage.Results.number" values={{ N: sailingsCount }} />
          </b>
          <UIResource id="ChooseVoyage.Results.match" />
        </div>
      </div>
      {isLoading ? (
        <AdvancedSelectionSkeleton />
      ) : (
        <section className="AdvancedSelection">
          <div className="AdvancedSelection__text">
            <UIResource id="ChooseVoyage.Results.showing" />{' '}
            <b suppressHydrationWarning>
              <UIResource id="ChooseVoyage.Results.number" values={{ N: sailingsCount }} />
            </b>
            <UIResource id="ChooseVoyage.Results.match" />
          </div>
          <div className="AdvancedSelection__action">
            <div className="sortDropdown">
              <SortPriceType />
            </div>
            <div className="sortDropdown">
              <SortRefinementForPackages />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AdvancedSelection;
