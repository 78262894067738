import cn from 'classnames';

import { getIsAccessKeyStateValid, selectAccessKeyState } from '@/ducks/accessKeys';
import { selectFilteredPackages, selectMainPackagesIsLoaded } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect';
import { useAppSelector } from '@/store';

import PackageCardList from './PackageCardList/PackageCardList';
import PackageCardListSkeleton from './PackageCardList/Skeleton';

import './PackageCardDetails.scss';

const PackageCardDetails = () => {
  const isLoaded = useAppSelector(selectMainPackagesIsLoaded);
  const isLoading = useAppSelector(selectIsFilteringLoading);
  const packages = useAppSelector(selectFilteredPackages);
  const isAKValid = getIsAccessKeyStateValid(useAppSelector(selectAccessKeyState));

  // Scroll back to the top of the page during loading
  useDidUpdateEffect(() => {
    if (!isLoaded || isLoading) {
      scrollIntoViewAsync('[data-scroll-to-when-loading]', undefined, { block: 'nearest' }, 0);
    }
  }, [isLoaded, isLoading]);

  const cssClasses = cn('PackageCardChooseVoyage', {
    isAKWrapper: isAKValid,
    isSinglePackage: packages?.length === 1,
  });

  if (!isLoaded || isLoading) return <PackageCardListSkeleton className={cssClasses} id="PackageCardChooseVoyage" />;

  return (
    <div className={cssClasses} id="PackageCardChooseVoyage">
      <PackageCardList packages={packages} />
    </div>
  );
};

export default PackageCardDetails;
