import type { AppDispatch } from '@/store';

import { clearLoggedInSessionData } from '@/actions';
import { BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS, SAILOR_DETAILS_BOOK_NOW_CLEAR } from '@/constants/actionTypes';
import {
  removeEncResId,
  removePaymentBooking,
  removePaymentOptionDesc,
  removePaymentPaymentOption,
  removePaymentReservationNumber,
  removePaymentToken,
} from '@/helpers/managers/payment';

export const cleanupPaidBooking = () => (dispatch: AppDispatch) => {
  dispatch({
    payload: '',
    type: BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS,
  });
  dispatch({
    type: SAILOR_DETAILS_BOOK_NOW_CLEAR,
  });

  dispatch(clearLoggedInSessionData());

  removePaymentBooking();
  removePaymentOptionDesc();
  removePaymentToken();
  removePaymentReservationNumber();
  removePaymentPaymentOption();
  removeEncResId();
};
