import { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';

import Image from '@/components/Image';
import ShareButton from '@/components/ShareButton';
import { FormattedMessage, useFormattedMessageContext } from '@/helpers/formatted-message';

import './PromotionBannerFlyout.scss';

const VIEW_SAILINGS_CMS_OPTIONS = {
  defaultMessage: 'VIEW THE SAILINGS',
  id: 'promotionBanner.view.sailings.button',
};

type Props = {
  filterSailings?: () => void;
  onShareClick?: () => void;
  promotionBanner?: {
    bannerShareLink?: string;
    description?: string;
    flyoutHeroImage?: {
      alt?: string;
      src?: string;
    };
    headLine?: string;
    id?: string;
    title?: string;
    whatsIncluded?: Array<{
      description?: string;
      icon?: {
        alt?: string;
      };
      image?: {
        alt?: string;
      };
      isVip?: boolean;
      navigationOrder?: string;
      text?: string;
    }>;
  };
};

const PromotionBannerFlyout = ({ filterSailings, onShareClick, promotionBanner = {} }: Props) => {
  const { bannerShareLink, description, flyoutHeroImage, headLine, id, title, whatsIncluded } = promotionBanner;

  const { formatMessage } = useFormattedMessageContext();

  const viewSailingClickHandler = useCallback(() => {
    const buttonText = formatMessage(VIEW_SAILINGS_CMS_OPTIONS)?.[0];
    filterSailings?.(buttonText);
  }, [filterSailings]);

  if (isEmpty(promotionBanner)) {
    return null;
  }

  return (
    <div className="promotionBanner">
      <ShareButton link={bannerShareLink} modalAlign="end" modalSide="bottom" onShare={onShareClick} />
      <div className="promotionBanner__bckgImg">
        <img alt={flyoutHeroImage.alt} src={flyoutHeroImage.src} />
        <div className="content">
          <p className="label">
            <span className="txt">{headLine} </span>
            <span className="rectangle" />
          </p>
          <h2 className="title">{title}</h2>
          {id && (
            <button aria-label="View The Sailings" onClick={viewSailingClickHandler} type="submit">
              <FormattedMessage {...VIEW_SAILINGS_CMS_OPTIONS} />
            </button>
          )}
        </div>
      </div>
      <div className="promotionBanner__paragraph">{description}</div>
      <div className="promotionBanner__whatsIncluded">
        <ul className="whatsIncluded__list">
          {whatsIncluded?.map((extra) => (
            <li className="whatsIncluded__list__Item" key={extra.text}>
              <div className="whatsIncluded__list__ItemContainer">
                <div className="whatsIncluded__list__Icon">
                  <Image alt={extra.icon.alt} src={extra.icon.src} />
                </div>

                <div className="whatsIncluded__list__Text">
                  <span className="text">{extra.text}</span>
                  <span className="description">{extra.description}</span>
                  <div className="separator" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PromotionBannerFlyout;
