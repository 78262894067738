import type { AppDispatch, AppGetState } from '@/store';

import { FM_AGENCY_ID_KEY, FM_AGENT_ID_KEY, FM_BOOKING_CHANNEL_KEY } from '@/ducks/fm/constants';
import { selectAgentBookingChannel } from '@/ducks/fm/selectors';
import {
  MNVV_AGENCY_ID_KEY,
  MNVV_AGENT_ID_KEY,
  MNVV_BOOKED_DATE_KEY,
  MNVV_CHANNEL_ID_KEY,
  MNVV_MAX_DATE_KEY,
  MNVV_RESERVATION_NUMBER_KEY,
} from '@/ducks/mnvv/constants';
import { mnvvDataClear } from '@/ducks/mnvv/slice';
import { removeParticularSearchParams, removeSearchParamsExcept } from '@/ducks/routes/history';

export const resetMnvvSearchCriteria = () => (_dispatch: AppDispatch) => {
  removeSearchParamsExcept([
    MNVV_RESERVATION_NUMBER_KEY,
    MNVV_CHANNEL_ID_KEY,
    MNVV_AGENT_ID_KEY,
    MNVV_AGENCY_ID_KEY,
    'bookingChannel',
  ]);
};

export const resetMnvvFlow = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const canRemoveBookingChannel =
    selectAgentBookingChannel(state) === new URLSearchParams(window.location.search).get(FM_BOOKING_CHANNEL_KEY);

  dispatch(mnvvDataClear());

  removeParticularSearchParams([
    FM_AGENCY_ID_KEY,
    FM_AGENT_ID_KEY,
    MNVV_BOOKED_DATE_KEY,
    MNVV_RESERVATION_NUMBER_KEY,
    MNVV_CHANNEL_ID_KEY,
    MNVV_MAX_DATE_KEY,
    ...(canRemoveBookingChannel ? [FM_BOOKING_CHANNEL_KEY] : []),
  ]);
};
