import { createSelector } from '@reduxjs/toolkit';

import type { TCabinCategoryCabinType } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { selectMerchandisingBannerDetails } from '@/features/promoBanners/selectors';
import { formatDate } from '@/helpers/util/dateUtil';
import formatMoney from '@/helpers/util/formatMoney';
import { type RootState, useAppSelector } from '@/store';

const selectBarTab = createSelector(
  [(_, duration: number) => duration, (state: RootState) => selectMerchandisingBannerDetails(state)?.promotionAddons],
  (duration, promotionAddons) =>
    promotionAddons?.find(({ max, min, voyageLengthRestriction }) => {
      if (!max && !min) {
        return duration === parseInt(voyageLengthRestriction || '0', 10);
      }
      return (min == null || duration >= min) && (max == null || duration <= max);
    })?.name,
);

export const useGetBarTabAmount = (duration: number) => useAppSelector((state) => selectBarTab(state, duration));

export const selectBarTabEligibleCabins = createSelector(
  [
    (_, cabinTypes?: TCabinCategoryCabinType[]) => cabinTypes,
    selectMerchandisingBannerDetails,
    (state) => selectSailingData(state),
    selectCurrencyCode,
  ],
  (cabinTypes, merchandisingBannerDetails, sailingData, currencyCode) => {
    if (!sailingData || !merchandisingBannerDetails || !cabinTypes) return;

    const { duration, endDate, startDate, voyageId } = sailingData;
    const { barTabEligibleCabins, promotionAddons, sailingsToBeExcluded, voyageEnd, voyageStart } =
      merchandisingBannerDetails;

    const isEligibleDateRange = startDate > formatDate(voyageStart) && endDate < formatDate(voyageEnd);
    const barTabEligibleCabinsIds = new Set(barTabEligibleCabins.map((cabin) => cabin.id));
    const isEligibleCabinId = cabinTypes.some((cabin) => barTabEligibleCabinsIds.has(String(cabin.id)));
    const isExcludedVoyage = sailingsToBeExcluded?.includes(voyageId);
    const isBarTabEligible = isEligibleDateRange && !isExcludedVoyage && isEligibleCabinId;

    if (!isBarTabEligible || !duration) return;

    const barTabProps = promotionAddons.find(({ max, min, voyageLengthRestriction }) => {
      if (!max && !min) {
        return duration === parseInt(voyageLengthRestriction || '0', 10);
      }
      return (min == null || duration >= min) && (max == null || duration <= max);
    });
    if (!barTabProps) return;

    return formatMoney(Number(barTabProps.name), currencyCode);
  },
);
