import UIResource from '@/components/UIResource';
import { FiltersPriceType } from '@/infra/types/common/filters';

const PriceTypeMessage = ({ isVip = false, priceType }: { isVip?: boolean; priceType: FiltersPriceType }) => {
  const resourceMapper = {
    [FiltersPriceType.perCabin]: isVip ? 'PriceFootnote.flyout.perSuite' : 'PriceFootnote.flyout.perCabin',
    [FiltersPriceType.perSailor]: 'PriceFootnote.flyout.perSailor',
    [FiltersPriceType.sailorPerNight]: 'PriceFootNote.cabinModule.perSailorPerNight',
  } as const;

  return (
    <UIResource
      id={resourceMapper[priceType as keyof typeof resourceMapper] ?? resourceMapper[FiltersPriceType.perCabin]}
    />
  );
};

export default PriceTypeMessage;
