'use client';

import { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { differenceInCalendarDays } from 'date-fns';

import PriceTypeMessage from '@/components/PriceTypeMessage';
import { Skeleton } from '@/components/Skeleton';
import { selectServerISOtime } from '@/ducks/common/lookup/selectors';
import { useUIResource } from '@/ducks/common/resources';
import { selectConfigServiceFeatureFlags } from '@/ducks/common/settings';
import { selectSecondarySailors } from '@/ducks/travelParty/selectors';
import { buildTripInfo, isCurrencySupported, loadTripInfo } from '@/features/uplift/adapter';
import { selectUpliftConfig, selectUpliftIsReady, selectUpliftSupportedCurrencies } from '@/features/uplift/selectors';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { currentNYTime, parse } from '@/helpers/util/dateUtil';
import { imitateClickOnKeyEvent } from '@/hooks/useOnKeyDown';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import UpliftTooltipIcon from '../Icon/UpliftTooltipIcon';
import UIResource from '../UIResource';

import './Uplift.scss';

const defaultSkeletonProps = {
  height: '21px',
  width: '150px',
};

const {
  tracker: { createTracker },
  trackerConstants,
} = tagmanager;

const trackEvent = createTracker({});

type Props = {
  cabinName?: string;
  currencyCode?: string;
  customIcon?: React.ReactNode;
  flyout?: string;
  insuranceAmount?: number;
  isHorizontal?: boolean;
  isLoadUpliftDisabled?: boolean;
  isModalDisabled?: boolean;
  isTaxesIncluded?: boolean;
  isTooltipDisabled?: boolean;
  onTrack?: () => void;
  price?: number;
  priceType?: string;
  primarySailor?: Record<string, unknown>;
  renderWithWrapper?: (element: React.ReactNode) => React.ReactNode;
  sailing?: {
    embarkDate?: string;
    startDate?: string;
  };
  showOrFromLabel?: boolean;
  skeletonProps?: {
    baseColor?: string;
    height?: string;
    highlightColor?: string;
    width?: string;
  };
  trackerEventCategory?: string;
  upliftPriceType?: 'cruise_option' | 'total';
};

const Uplift = ({
  ariaLabel,
  cabinName = '',
  className,
  currencyCode = 'USD',
  customIcon = '',
  flyout,
  hasPriceTypeLabel,
  insuranceAmount = 0,
  isHorizontal,
  isLoadUpliftDisabled,
  isModalDisabled,
  isTaxesIncluded,
  isTooltipDisabled = true,
  onTrack,
  price = '',
  priceType = FiltersPriceType.perCabin,
  primarySailor = {},
  sailing = {},
  showOrFromLabel = true,
  skeletonProps = defaultSkeletonProps,
  trackerEventCategory,
  upliftPriceType = 'total',
}: Props) => {
  const serverISOtime = useAppSelector(selectServerISOtime);
  const upliftSettings = useAppSelector(selectUpliftConfig);
  const isUpliftReady = useAppSelector(selectUpliftIsReady);
  const additionalSailors = useAppSelector(selectSecondarySailors);
  const priceWithCents = Math.ceil(price * 100);
  const insuranceWithCents = insuranceAmount * 100;
  const [upliftPrice, setUpliftPrice] = useState('');

  useEffect(() => {
    if (!isLoadUpliftDisabled && isUpliftReady) {
      const tripInfo = buildTripInfo(
        priceWithCents,
        insuranceWithCents,
        sailing,
        primarySailor,
        cabinName,
        additionalSailors,
      );
      setTimeout(() => loadTripInfo(tripInfo), 200);
    }
  }, [
    additionalSailors,
    cabinName,
    insuranceWithCents,
    isLoadUpliftDisabled,
    isUpliftReady,
    priceWithCents,
    primarySailor,
    sailing,
  ]);

  const assignPriceSpanObserver = useCallback((element) => {
    const priceSpanMutationObserver = new MutationObserver((mutations) => {
      const addedRecord = (mutations || []).find((record) => record.addedNodes?.length);
      const priceNodeInnerText = addedRecord.target?.innerText;
      if (priceNodeInnerText?.length) {
        setUpliftPrice(priceNodeInnerText);
      }
    });
    if (!element) return;
    priceSpanMutationObserver.observe(element, { childList: true });
    return () => {
      priceSpanMutationObserver.disconnect();
    };
  }, []);

  const fromLabel = useUIResource(showOrFromLabel ? 'uplift.startPrice' : 'ItineraryRefinementCard.from');
  const monthText = useUIResource('uplift.month');

  if (
    !isCurrencySupported(currencyCode) ||
    !price ||
    price < upliftSettings?.minimumPrice?.[currencyCode] ||
    price > upliftSettings?.maximumPrice?.[currencyCode]
  ) {
    return null;
  }

  const minimumDays = upliftSettings?.minimumDays?.[currencyCode];
  if (sailing && minimumDays) {
    let sailingStartDate;
    if ('startDate' in sailing) {
      sailingStartDate = sailing.startDate;
    } else {
      sailingStartDate = sailing.embarkDate;
    }

    const currentDate = currentNYTime(serverISOtime);
    const daysUntilSailing = differenceInCalendarDays(parse(sailingStartDate), currentDate);
    if (daysUntilSailing < minimumDays) {
      return null;
    }
  }

  const trackUplift = () => {
    const label = `${trackerConstants.EVENT_LABELS.UPLIFT_INFO}${flyout ? `|${flyout}` : ''}`;
    tagmanager.tracker.common.trackIconClick({
      category: trackerEventCategory,
      label,
    });
    if (!isModalDisabled) {
      trackEvent({ action: 'displayed', module: 'uplift-price-context-pop-up' });
    }

    onTrack?.();
  };

  const onKeyDown = (e) => {
    imitateClickOnKeyEvent(e);
    trackUplift();
  };

  const currencySymbol = getSymbolFromCurrenciesData(currencyCode);

  const additionalProps = {};
  if (isModalDisabled) {
    additionalProps['data-up-disable-lightbox'] = true;
  }
  if (isTooltipDisabled) {
    additionalProps['data-up-disable-tooltip'] = true;
  }
  if (isTaxesIncluded) {
    additionalProps['data-up-taxes-included'] = true;
  }

  const dataUpPriceType = priceType === FiltersPriceType.perSailor ? 'per_person' : 'total';

  const classes = cn(className, 'uplift', {
    'uplift-font': showOrFromLabel,
  });

  const {
    baseColor: skeletonBaseColor,
    height: skeletonHeight,
    highlightColor: skeletonHighlightColor,
    width: skeletonWidth,
  } = skeletonProps;
  return (
    <span
      aria-label={`${ariaLabel || ''} ${fromLabel} ${upliftPrice} ${currencyCode}${monthText}`}
      className={classes}
      data-up-price-model={dataUpPriceType}
      data-up-price-type={upliftPriceType}
      data-up-price-value={priceWithCents}
      onKeyDown={onKeyDown}
      onMouseDown={trackUplift}
      onTouchStart={trackUplift}
      role="button"
      tabIndex="0"
      // Need to spread props because if the prop is present with false value it still takes effect
      {...additionalProps}
    >
      {isHorizontal ? (
        <>
          {!upliftPrice && (
            <Skeleton
              baseColor={skeletonBaseColor}
              height={skeletonHeight}
              highlightColor={skeletonHighlightColor}
              width={skeletonWidth}
            />
          )}
          <div className={cn({ hidden: !upliftPrice }, 'upliftPriceInfo')}>
            <span className="uplift__from">{fromLabel}</span>
            <span className="modal-linkContainer">
              <span className="modal-link">
                <span className="uplift__price">
                  {currencySymbol}
                  <span className="price" data-up-from-currency-unit-major="" ref={assignPriceSpanObserver} />
                  <span className="month">{monthText}</span>
                </span>
                <span className="tooltip" data-up-tooltip="">
                  {customIcon || <UpliftTooltipIcon />}
                </span>
              </span>
            </span>
          </div>
        </>
      ) : (
        <>
          {!upliftPrice && (
            <Skeleton
              baseColor={skeletonBaseColor}
              height={skeletonHeight}
              highlightColor={skeletonHighlightColor}
              width={skeletonWidth}
            />
          )}
          <div
            aria-label={`See uplift financing pricing from ${currencySymbol}${upliftPrice}/month`}
            className={cn({ hidden: !upliftPrice })}
          >
            <span className="flex-price">
              {hasPriceTypeLabel && (
                <>
                  <PriceTypeMessage priceType={priceType} />{' '}
                </>
              )}
              <span className="from-label">{fromLabel} </span>
              <span className="modal-link">
                {currencySymbol}
                <span>
                  <span className="price" data-up-from-currency-unit-major="" ref={assignPriceSpanObserver}>
                    <Skeleton inline width="20px" />
                  </span>
                  <UIResource id="uplift.month" />
                </span>
              </span>
            </span>
            <span aria-label="uplift modal tooltip" className="tooltip" data-up-tooltip="">
              {customIcon || <UpliftTooltipIcon />}
            </span>
          </div>
        </>
      )}
    </span>
  );
};

export const UpliftWithSkeleton = (props: Props) => {
  const {
    className,
    currencyCode,
    isShowUplift,
    renderWithWrapper = (e) => e,
    showOrFromLabel = true,
    skeletonProps = defaultSkeletonProps,
  } = props;
  const classes = cn(className, 'uplift', {
    'uplift-font': showOrFromLabel,
  });
  const {
    baseColor: skeletonBaseColor,
    height: skeletonHeight,
    highlightColor: skeletonHighlightColor,
    width: skeletonWidth,
  } = skeletonProps;
  const isUpliftEnabled = useAppSelector((state) => !!selectConfigServiceFeatureFlags(state)?.uplift);
  const isUpliftReady = useAppSelector(selectUpliftIsReady);
  const currencies = useAppSelector(selectUpliftSupportedCurrencies);
  const isUpliftCurrencySupported = currencies.includes(currencyCode);

  if (!isUpliftEnabled || !isUpliftCurrencySupported) return;

  if (!isShowUplift || !isUpliftReady) {
    return renderWithWrapper(
      <span className={classes}>
        <Skeleton
          baseColor={skeletonBaseColor}
          height={skeletonHeight}
          highlightColor={skeletonHighlightColor}
          width={skeletonWidth}
        />
      </span>,
    );
  }
  return renderWithWrapper(<Uplift {...props} className={classes} />);
};

export default Uplift;
