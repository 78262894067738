import React from 'react';

import { Skeleton } from '@/components/Skeleton';
import { getColorFromSCSS } from '@/helpers/styles/getColorFromSCSS';

import PackagePriceSkeleton from '../PackagePrice/PackagePriceSkeleton';

import './PackageCardMedia.scss';

export const SKELETON_PROPS = {
  baseColor: getColorFromSCSS('gray-420'),
  highlightColor: getColorFromSCSS('gray-410'),
};

const PackageCardMediaSkeleton = () => (
  <div className="PackageCard__media">
    <div className="mediaImg">
      <Skeleton {...SKELETON_PROPS} />
    </div>
    <div className="mediaText">
      <div className="mediaText__top">
        <div className="packageName">
          <Skeleton inDarkBg />
        </div>
        <div className="portsOfCalls">
          <Skeleton inDarkBg width={16} />
          <div className="ports">
            <Skeleton inDarkBg width={200} />
          </div>
        </div>
      </div>

      <div className="mediaText__bottom">
        <PackagePriceSkeleton />

        <div className="shipNameDuration">
          <div className="duration">
            <span className="durationIcon">
              <Skeleton inDarkBg inline width={17} />
            </span>
            <span className="durationNumber">
              <Skeleton inDarkBg inline width={100} />
            </span>
          </div>
          <div className="shipNameIcon">
            <span className="shipIcon">
              <Skeleton inDarkBg inline width={17} />
            </span>
            <span className="shipName">
              <Skeleton inDarkBg inline width={100} />
            </span>
          </div>
        </div>
        <div className="fullCruiseBtn">
          <Skeleton height={50} inDarkBg rounded width="30%" />
        </div>
      </div>
    </div>
  </div>
);

export default PackageCardMediaSkeleton;
