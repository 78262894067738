import { type ComponentProps } from 'react';

import cn from 'classnames';

import PackageCardSkeleton from '../PackageCard/PackageCardSkeleton';

type TProps = ComponentProps<'div'>;

const PackageCardListSkeleton = ({ className, ...restAttrs }: TProps) => (
  <div {...restAttrs} className={cn('PackageCardChooseVoyage', className)}>
    <PackageCardSkeleton key={1} />
    <PackageCardSkeleton key={2} />
    <PackageCardSkeleton key={3} />
  </div>
);

export default PackageCardListSkeleton;
