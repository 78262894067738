import { forwardRef } from 'react';

import cn from 'classnames';
import ReactPlayer from 'react-player';

import styles from './Video.module.scss';

type VideoProps = React.ComponentPropsWithoutRef<typeof ReactPlayer> & {
  fillsParentContainer?: boolean;
  isHidden?: boolean;
  /** Poster for the video */
  poster?: string;
  /** URL for the video */
  video: string;
  videoRef?: React.Ref<ReactPlayer>;
};

const Video = forwardRef<HTMLDivElement, VideoProps>(
  ({ fillsParentContainer, isHidden, poster, video, videoRef, ...props }, ref) => (
    <div className={cn(styles.root, fillsParentContainer && styles.fillsParent, isHidden && styles.hidden)} ref={ref}>
      <ReactPlayer
        className={styles.video}
        config={{ file: { attributes: { poster } } }}
        height="100%"
        ref={videoRef}
        url={video}
        width="100%"
        {...props}
      />
    </div>
  ),
);
Video.displayName = 'Video';

export default Video;
