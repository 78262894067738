import isEqual from 'lodash/isEqual';

import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import decodeAKFromUrlParam from '@/ducks/accessKeys/decodeAKFromUrlParam';
import {
  selectDefaultFilterSailors,
  selectFiltersOptionsDefaultEndDate,
  selectFiltersOptionsDefaultStartDate,
} from '@/ducks/filtersOptions';
import { getVoyagesApiPayload } from '@/ducks/pages/chooseVoyage/actions/fetchPackages';
import { selectVoyagesApiPayload } from '@/ducks/pages/chooseVoyage/selectors';
import { searchStart } from '@/ducks/pages/chooseVoyage/slice';
import useOnRouteChange from '@/ducks/routeChange/useOnRouteChange';
import { useAppStore } from '@/store';

const useClearVoyages = () => {
  const { dispatch, getState } = useAppStore();

  useOnRouteChange(({ areParamsChanged, isPathChanged, nextParams }) => {
    if (!isPathChanged && areParamsChanged) {
      const { dateFrom, dateTo, fromDate, metaType, sailors, toDate } = (nextParams || {}) as Record<string, string>;
      const accessKey = decodeAKFromUrlParam(nextParams?.[ACCESS_KEY_PARAM] as string);
      const state = getState();
      const defaultSailors = selectDefaultFilterSailors(state);
      const defaultStartDate = selectFiltersOptionsDefaultStartDate(state);
      const defaultEndDate = selectFiltersOptionsDefaultEndDate(state);
      const args = {
        accessKey,
        dateFrom: dateFrom || fromDate || defaultStartDate,
        dateTo: dateTo || toDate || defaultEndDate,
        metaType,
        sailors: sailors ? +sailors : defaultSailors,
      };
      const nextPayload = dispatch(getVoyagesApiPayload(args));
      const currentPayload = selectVoyagesApiPayload(state);
      if (!isEqual(nextPayload, currentPayload)) dispatch(searchStart(nextPayload));
    }
  });
};

export default useClearVoyages;
